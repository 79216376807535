import { SuperModel } from './super-model.model';
import { ERoles } from '@common/enums';

export class User extends SuperModel {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  role: ERoles[]; // TODO: define EUserRoles enum

  constructor(data: any = {}) {
    super(data);

    this.firstName = data?.family_name;
    this.lastName = data?.given_name;
    this.email = data?.email;
    this.role = data?.role || [];
    this.username = data?.preferred_username;
    this.id = data?.sub;
  }

  get serialised() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
    };
  }
}
