import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';
import { EBitfAuthState } from '@bitf/enums';

import { AuthService } from '@services';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  subscriptions: Subscription = new Subscription();
  eBitfAuthState = EBitfAuthState;
  authState: EBitfAuthState = EBitfAuthState.TO_BE_LOGGED;

  constructor(private authService: AuthService) {}

  @BitfTryCatch()
  ngOnInit() {
    this.subscriptions.add(
      this.authService.authState$.subscribe((authState: EBitfAuthState) => {
        this.authState = authState;

        if (authState === EBitfAuthState.LOGGED) {
          console.log('Logged in');
          setTimeout(() => {
            this.authService.handleRedirect();
          }, 2000);
        }
      })
    );
    this.authService.handleAuthentication();
  }

  retrySignIn() {
    this.authService.handleAuthentication();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
