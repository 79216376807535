import { HttpResponse } from '@angular/common/http';

import { BitfRestEnvelopeMapper } from '@bitf/parsers/rest-parser/bitf-rest-envelope.mapper';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';

import {
  IBitfEnvelopeMapper,
  IBitfEnvelopeMapperData,
  IBitfApiResponse,
  IBitfApiPagination,
  IBitfApiUiMessage,
} from '@interfaces';
import { IBitfApiNetPaginationResponse } from './bitf-net-api.interface';
import { EBitfUiMessageType } from '../../services/api/bitf-api.enum';

export abstract class BitfNetEnvelopeMapper extends BitfRestEnvelopeMapper implements IBitfEnvelopeMapper {
  map({ req, event }: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    event = super.map({ req, event });
    const mappedBody: IBitfApiResponse<any> = event.body;
    const originalBody = event.body.originalBody;

    if (originalBody != null) {
      const payload = originalBody.payload;
      const isPaginated = bitfGetProp(payload, 'totalElements');

      if (isPaginated) {
        const { content, ...pagination } = payload;
        // NOTE: Map items -> content
        mappedBody.content = content;

        mappedBody.pagination = this.mapPagination(pagination);

        // mappedBody.sorting = this.mapSorting(sorting);
      } else if (payload) {
        mappedBody.content = payload.content;
      }

      if (originalBody.metadata) {
        mappedBody.metadata = originalBody.metadata;

        let uiMessages: IBitfApiUiMessage[] = [];
        const bodyUiMessages = originalBody.metadata.uiMessages;
        if (bodyUiMessages) {
          const uiErrorMessages = this.mapUIMessagesByType(
            bodyUiMessages?.errors || [],
            EBitfUiMessageType.ERROR
          );
          const uiInfoMessages = this.mapUIMessagesByType(
            bodyUiMessages?.infos || [],
            EBitfUiMessageType.INFO
          );
          const uiWarningMessages = this.mapUIMessagesByType(
            bodyUiMessages?.warnings || [],
            EBitfUiMessageType.WARNING
          );
          uiMessages = [...uiErrorMessages, ...uiInfoMessages, ...uiWarningMessages];
          mappedBody.metadata.uiMessages = uiMessages;
        }
      }
    }

    return event.clone({
      body: mappedBody,
    });
  }

  mapPagination(pagination: IBitfApiNetPaginationResponse): IBitfApiPagination {
    if (!pagination) {
      return;
    }
    const mappedPagination: IBitfApiPagination = {
      first: pagination.first,
      last: pagination.last,
      page: pagination.number,
      size: pagination.pageSize,
      itemsInPage: pagination.numberOfElements,
      totalItems: pagination.totalElements,
      totalPages: pagination.totalPages,
    };
    return mappedPagination;
  }

  mapUIMessagesByType(uiMessages: IBitfApiUiMessage[], type: EBitfUiMessageType): IBitfApiUiMessage[] {
    return uiMessages.map((uiMessage: any) => ({
      ...uiMessage,
      message: uiMessage.message || uiMessage.text,
      code: uiMessage.code || uiMessage,
      type,
    }));
  }
}
