import { BITF_VALIDATION_MESSAGES } from '.';
import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('COMMON.META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('COMMON.META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('COMMON.META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES: [{ code: 'it', label: 'Italiano' }],
  DEFAULT_LANGUAGE: { code: 'it', label: 'Italiano' },
  LOCAL_STORAGE_VERSION: 1,
  VALIDATION_MESSAGES: {
    ...BITF_VALIDATION_MESSAGES,
  },
};
