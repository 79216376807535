import {
  BitfMockDbPathBuilder,
  BitfMockDbRequestMapper,
  BitfMockDbResponseMapper,
  BitfMockDbEnvelopeMapper,
} from '@bitf/core/parsers/mock-db-parser';
import {
  AppEnvelopeMapper,
  AppPathBuilder,
  AppRequestMapper,
  AppResponseMapper,
} from '@common/core/parsers/app-parser';

import { IBitfParserStrategy } from '@interfaces';

export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: { interceptHttpResponsesWithCode: [401, '4XX', '5XX'] },
  toastMessage: { durationMs: 5000 },
  parsers: {
    defaultParser: 'appParser',
    parserStrategies: {
      appParser: {
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      mockDbParser: {
        pathBuilder: new BitfMockDbPathBuilder(),
        requestMapper: new BitfMockDbRequestMapper(),
        responseMapper: new BitfMockDbResponseMapper(),
        envelopeMapper: new BitfMockDbEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
};
