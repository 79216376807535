import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInComponent } from './core/components/sign-in/sign-in.component';

const routes: Routes = [
  // NOTE: do not change the order or routes
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  // NOTE: do not change the order or routes
  {
    path: '',
    loadChildren: () => import('./modules/desktop/desktop.module').then(m => m.DesktopModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
