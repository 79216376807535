import { NgModule, ErrorHandler } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { BitfOAuthInterceptor } from '@bitf/interceptors/bitf-oauth-interceptor.service';
import { BitfRetryInterceptor } from '@bitf/interceptors/bitf-retry-interceptor.service';
import { BitfApiUiMessagesInterceptor } from '@bitf/interceptors/bitf-api-ui-messages-interceptor.service';
import { BitfLanguageInterceptor } from '@bitf/interceptors/bitf-language-interceptor.service';
import { BitfApiErrorsInterceptor } from '@bitf/interceptors/bitf-api-errors-interceptor.service';
import { BitfApiEnvelopeMapperInterceptor } from '@bitf/interceptors/bitf-api-envelope-mapper-interceptor.service';

import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';

import { APP_VERSION } from '@env/version';

const EXPORTS_COMPONENTS = [];
const COMPONENTS = [];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${APP_VERSION}`);
}

@NgModule({ declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
    exports: [...EXPORTS_COMPONENTS], imports: [BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BitfOAuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BitfLanguageInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BitfApiErrorsInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BitfApiUiMessagesInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BitfApiEnvelopeMapperInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BitfRetryInterceptor,
            multi: true,
        },
        { provide: ErrorHandler, useClass: BitfErrorHandlerService },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class CommonCoreModule {}
