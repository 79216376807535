import { Injector } from '@angular/core';
import Keycloak, { KeycloakTokenParsed } from 'keycloak-js';
import { BitfAuthService } from '../bitf-auth.service';
import { IBitfKeycloakLogin, IBitfKeycloakTokenMetadata } from './bitf-keycloak-auth.interface';
import { environment } from '@env/environment';
import { EBitfAuthState } from '../auth.enum';
import { BitfTryCatch } from '@common/core/decorators';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { User } from '@common/core/models';
import { jwtDecode } from 'jwt-decode';
import { from } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class BitfKeycloakAuthService extends BitfAuthService<
  IBitfKeycloakLogin,
  IBitfKeycloakTokenMetadata
> {
  private keycloak: Keycloak;
  private activatedRoute: ActivatedRoute;

  constructor(protected injector: Injector) {
    super(injector);
    this.activatedRoute = this.injector.get(ActivatedRoute);

    this.initKeycloakLibrary();
  }

  signIn(loginResponse: IBitfKeycloakLogin) {
    super.signIn(loginResponse);
    this.activateRefreshToken();
  }

  signOut(): void {
    this.clearAuthData();
    this.keycloak.logout();
  }

  @BitfTryCatch()
  handleAuthentication(queryParamVarName = 'code') {
    const code = this.activatedRoute.snapshot.queryParamMap.get(queryParamVarName);
    if (code) {
      this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);
    } else if (this.keycloak.authenticated) {
      this.loadUserInfo();
    } else {
      this.authState$.next(EBitfAuthState.TO_BE_LOGGED);
      this.keycloak.login();
    }
  }

  @BitfTryCatch()
  decodeToken(loginResponse: IBitfKeycloakLogin): IBitfKeycloakTokenMetadata {
    if (!loginResponse) {
      return undefined;
    }

    const decodedToken: KeycloakTokenParsed = jwtDecode(loginResponse.accessToken);

    Object.assign(decodedToken, {
      token: loginResponse.accessToken,
      refreshToken: loginResponse.refreshToken,
      expiresAt: decodedToken.exp * 1000,
      expiresIn: loginResponse.expiresIn * 1000,
    });

    return decodedToken as IBitfKeycloakTokenMetadata;
  }

  @BitfTryCatch()
  async renewToken(): Promise<IBitfKeycloakLogin> {
    return this.keycloak.updateToken(30).then(refreshed => {
      if (refreshed) {
        return {
          accessToken: this.keycloak.token,
          refreshToken: this.keycloak.refreshToken,
          expiresIn: this.keycloak.tokenParsed.exp - this.keycloak.tokenParsed.iat,
          expiresAt: this.keycloak.tokenParsed.exp,
        };
      }
    });
  }

  override loginUserWithToken(next?: ActivatedRouteSnapshot) {
    if (this.keycloak.authenticated) {
      return from(this.loadUserInfo()).pipe(
        map(_ => {
          if (this.keycloak.userInfo) {
            return true;
          } else {
            throw new Error('Empty user info');
          }
        })
      );
    } else {
      this.signOut();
    }
  }

  private loadUserInfo() {
    return this.keycloak
      .loadUserInfo()
      .then(profile => {
        this.setUser(new User(profile));
        this.authState$.next(EBitfAuthState.LOGGED);
      })
      .catch(() => {
        this.signOut();
      });
  }

  private initKeycloakLibrary() {
    this.keycloak = new Keycloak({
      url: environment.keycloak.url,
      realm: environment.keycloak.realm,
      clientId: environment.keycloak.clientId,
    });

    this.keycloak.onAuthSuccess = () => {
      this.signIn({
        accessToken: this.keycloak.token,
        refreshToken: this.keycloak.refreshToken,
        expiresIn: this.keycloak.tokenParsed.exp - this.keycloak.tokenParsed.iat,
        expiresAt: this.keycloak.tokenParsed.exp,
      });
      this.loadUserInfo();
    };

    this.keycloak.onAuthRefreshSuccess = () => {
      this.signIn({
        accessToken: this.keycloak.token,
        refreshToken: this.keycloak.refreshToken,
        expiresIn: this.keycloak.tokenParsed.exp - this.keycloak.tokenParsed.iat,
        expiresAt: this.keycloak.tokenParsed.exp,
      });
    };

    this.keycloak.onAuthError = error => {
      this.authState$.next(EBitfAuthState.LOGIN_ERROR);
    };

    this.keycloak.init({
      scope: environment.keycloak.scope,
      redirectUri: `${window.location.origin}/sign-in`,
      checkLoginIframe: false,
      responseMode: 'query',
      pkceMethod: 'S256',
      refreshToken: this.authTokenMetaData?.refreshToken,
      token: this.authTokenMetaData?.token,
      idToken: this.authTokenMetaData?.token,
    });
  }
}
