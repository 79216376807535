import { Injectable, Injector } from '@angular/core';
import { BitfKeycloakAuthService } from '@common/libs/bitforce/core/services/auth/keycloak/bitf-keycloak-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfKeycloakAuthService {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
