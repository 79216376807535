import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule, UntypedFormBuilder } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { BitfFormBuilderService } from '@common/libs/bitforce/core/form';
import { BitfFormControlTranslatePipe } from '@common/libs/bitforce/pipes/translate/bitf-form-control-translate.pipe';
import { BitfDatePipe } from '@common/libs/bitforce/pipes/bitf-date.pipe';

const SHARED_MODULES = [CommonModule, ReactiveFormsModule, FormsModule, TranslateModule];
const SHARED_COMPONENTS = [];
const SHARED_DIRECTIVES = [];
const SHARED_BITF_COMPONENTS = [];
const SHARED_PIPES = [BitfFormControlTranslatePipe, BitfDatePipe];
const PROVIDERS = [[{ provide: UntypedFormBuilder, useClass: BitfFormBuilderService }]];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [
    ...SHARED_MODULES,
    ...SHARED_COMPONENTS,
    ...SHARED_DIRECTIVES,
    ...SHARED_BITF_COMPONENTS,
    ...SHARED_PIPES,
  ],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_BITF_COMPONENTS, ...SHARED_PIPES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [...PROVIDERS],
})
export class CommonSharedModule {
  // This should only be called inside the CoreModule!
}
