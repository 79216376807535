import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';
import {
  EBitfApiRequestPartsNames,
  EApiRequestPartKeys,
  EBitfPQueryComparators,
  EBitfPQueryOperator,
} from '@enums';
import { IBitfPartData } from '@interfaces';
import { BitfOption } from '../../models';

export class BitfPFormItemConfigApiRequestPart extends BitfApiRequestPart {
  constructor({
    key,
    partName,
    defaultPartData,
  }: {
    key?: EApiRequestPartKeys;
    partName?: EBitfApiRequestPartsNames;
    defaultPartData?: IBitfPartData;
  } = {}) {
    if (!key) {
      key = EApiRequestPartKeys.FILTERS;
    }
    if (!partName) {
      partName = EBitfApiRequestPartsNames.QUERY;
    }
    super(key, partName, defaultPartData);
  }

  build(): void {
    const queryItems = [];
    if (this.formValue && Object.keys(this.formValue).length) {
      Object.entries(this.formValue)
        .filter(([_, value]) => {
          if (Array.isArray(value)) {
            return value.length;
          }
          return value !== null && value !== '' && value !== undefined;
        })
        .forEach(([key, value]: [string, any]) => {
          if (bitfGetProp(this.data, 'formItemsConfig', key, 'type') === 'tree') {
            Object.keys(value)
              .filter(_key => value[_key] && value[_key].length)
              .forEach((_key: string) => {
                let mappedValue = value[_key];
                if (Array.isArray(value[_key]) && value[_key].length) {
                  if (!value[_key][0].id) {
                    mappedValue = value[_key].join(',');
                  }
                }

                const mapName = bitfGetProp(this.data, 'formItemsConfig', key, 'mapName');

                queryItems.push({
                  name: mapName[_key] || _key,
                  value: mappedValue,
                  comparator:
                    bitfGetProp(this.data, 'formItemsConfig', key, 'comparator') ||
                    EBitfPQueryComparators.EQUAL,
                  operator:
                    bitfGetProp(this.data, 'formItemsConfig', key, 'operator') || EBitfPQueryOperator.AND,
                });
              });
          } else {
            let mappedValue = value;
            if (Array.isArray(value) && value.length) {
              if (!value[0].id) {
                mappedValue = value.join(',');
              } else if (value.every(v => v instanceof BitfOption)) {
                // autocomplete chips case
                mappedValue = value.map(v => v.id).join(',');
              }
            }

            queryItems.push({
              name: this.mapName(key),
              value: mappedValue,
              comparator:
                bitfGetProp(this.data, 'formItemsConfig', key, 'comparator') || EBitfPQueryComparators.EQUAL,
              operator: bitfGetProp(this.data, 'formItemsConfig', key, 'operator') || EBitfPQueryOperator.AND,
            });
          }
        });
    } else {
      const formValue = {};
      for (const [key, value] of Object.entries(this.data?.formItemsConfig || {}) as any) {
        if (
          value.setDefault &&
          ((Array.isArray(value.default) && value.default.length) ||
            (!Array.isArray(value.default) && value.default))
        ) {
          formValue[key] = value.default;
        }
      }
      if (Object.entries(formValue).length) {
        this.formValue = formValue;
        this.build();
        return;
      }
    }

    this.part = queryItems;
  }

  private mapName(name: string) {
    const mapName = bitfGetProp(this.data, 'formItemsConfig', name, 'mapName');
    return mapName || name;
  }
}
