import { SuperModel } from './super-model.model';

export class VoucherReport extends SuperModel {
  purchaseDate: Date;
  orderNumber: number;
  customerEmail: string;
  pnr: string;
  voucherCreation: Date;
  voucher: string;
  constructor(data: Partial<VoucherReport> = {}) {
    super(data);
    this.purchaseDate = new Date(data.purchaseDate);
  }
  get serialised() {
    return {
      id: this.id,
      purchaseDate: this.purchaseDate,
      orderNumber: this.orderNumber,
      customerEmail: this.customerEmail,
      pnr: this.pnr,
      voucherCreation: this.voucherCreation,
      voucher: this.voucher,
    };
  }
}
