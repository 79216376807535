import { Injectable } from '@angular/core';

import { IBitfUiRoleManagerService } from '@interfaces';
import { ERoleActions } from '@enums';
import { StoreService } from '@services';
import { User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class UiRoleManagerService implements IBitfUiRoleManagerService {
  constructor(private storeService: StoreService) {}

  canI(action, data?: any): boolean {
    switch (action) {
      case ERoleActions.VIEW_COLUMN:
        return false;
      default:
        return false;
    }
  }
}
