import { Injector } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Subject, TimeoutError, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { marker as bitfToTranslate } from '@biesbjerg/ngx-translate-extract-marker';
import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';
import { BitfErrorHandlerService } from '@bitf/services/error-handler/bitf-error-handler.service';

import { User, Store } from '@models';
import { AppSessionService, UsersService, StoreService, StorageService } from '@services';
import { BITF_CONFIGS } from '@configs';
import { EBitfAuthState, eStoreActions } from '@enums';
import { environment } from '@env/environment';

import {
  IBitfTokenState,
  IBitfTokenMetadata,
  IBitfLogin,
  IBitfUiMessages,
  IBitfToastData,
  IBitfApiResponse,
} from '@interfaces';
import { EBitfUiMessageType, EBitfUiMessageTarget } from '@enums';

export abstract class BitfAuthService<LoginType extends IBitfLogin, TokenType extends IBitfTokenMetadata> {
  private renewTokenRetryAttempt = 0;
  private renewTokenMaxAttempt = 3;
  private renewTokenDelay = 3000;
  _authTokenMetaData: any;
  authState$: Subject<EBitfAuthState>;
  tokenEvents$ = new Subject<IBitfTokenState>();
  router: Router;
  appSessionService: AppSessionService;
  refreshTokenHandler: any;
  tokenExpirationHandler: any;
  tokenAlertTresholdMinutes = 10;
  usersService: UsersService;
  storeService: StoreService;
  translateService: TranslateService;
  bitfErrorHandlerService: BitfErrorHandlerService;
  protected storageService: StorageService;

  constructor(protected injector: Injector) {
    this.router = this.injector.get(Router);
    // FIXME @kouti
    setTimeout(() => {
      this.appSessionService = this.injector.get(AppSessionService);
    }, 0);
    this.usersService = this.injector.get(UsersService);
    this.storeService = this.injector.get(StoreService);
    this.translateService = this.injector.get(TranslateService);
    this.bitfErrorHandlerService = this.injector.get(BitfErrorHandlerService);
    this.storageService = injector.get(StorageService);

    this.authState$ = new Subject<EBitfAuthState>();

    if (this.isTokenValid()) {
      this.activateRefreshToken();
    }
  }

  abstract handleAuthentication(...args: any[]): void;
  abstract decodeToken(loginResponse: LoginType): TokenType;
  extractRenewToken(response: HttpResponse<any>): any {}
  signInWithToken(newToken: unknown) {}

  get authTokenMetaData(): TokenType {
    if (this._authTokenMetaData) {
      return this._authTokenMetaData;
    }
    const metaData = localStorage.getItem(`${environment.appName}-TokenMetadata`);
    if (metaData) {
      this._authTokenMetaData = JSON.parse(metaData);
      return this._authTokenMetaData;
    }
    return undefined;
  }

  set authTokenMetaData(authTokenMetaData: TokenType) {
    // NOTE: the authTokenMetaData value is in the authService memory not in the store
    this.storeService.setStore(() => {
      if (!authTokenMetaData) {
        clearTimeout(this.refreshTokenHandler);
        localStorage.removeItem(`${environment.appName}-TokenMetadata`);
        this._authTokenMetaData = undefined;
      } else {
        this._authTokenMetaData = authTokenMetaData;
        localStorage.setItem(`${environment.appName}-TokenMetadata`, JSON.stringify(authTokenMetaData));
        this.activateRefreshToken();
      }
    }, eStoreActions.SET_AUTH_TOKEN);
  }

  get tokenState(): IBitfTokenState {
    return {
      isExpired: this.isTokenExpired(),
      isExpiring: this.isTokenExpiring(),
    } as IBitfTokenState;
  }

  signIn(loginResponse: LoginType) {
    this.authTokenMetaData = this.decodeToken(loginResponse);
    this.authState$.next(EBitfAuthState.TOKEN_RETRIEVED);
    if (loginResponse && loginResponse.user) {
      this.setUser(loginResponse.user);
    }
  }

  clearAuthData() {
    this.setUser(undefined);
    this.authTokenMetaData = undefined;
    if (this.refreshTokenHandler) {
      clearTimeout(this.refreshTokenHandler);
    }
  }

  signOut() {
    this.clearAuthData();
    this.authState$.next(EBitfAuthState.TO_BE_LOGGED);
    this.redirectAfterSignOut();
  }

  protected redirectAfterSignOut() {
    // NOTE: Important leave the browser the time to clear cookies and localstorage before tro try
    // another autologin from the sign-in page
    setTimeout(() => {
      location.href = BITF_CONFIGS.urls.signOutUrl;
    }, 1000);
  }

  loginUserWithToken(next?: ActivatedRouteSnapshot) {
    return this.usersService.getMe().pipe(
      map((response: IBitfApiResponse<User>) => {
        this.setUser(response.content);
        if (!response.content) {
          throw new Error('Empty user!');
        }
        return true;
      }),
      catchError(error => {
        if (!(error instanceof TimeoutError)) {
          this.signOut();
        }
        throw error;
      })
    );
  }

  isTokenValid(): boolean {
    return !this.isTokenExpired();
  }

  isTokenExpired(): boolean {
    if (this.authTokenMetaData && this.authTokenMetaData.expiresAt) {
      return Date.now() > this.authTokenMetaData.expiresAt;
    }
    return true;
  }

  isTokenExpiring(): boolean {
    if (!this.tokenAlertTresholdMinutes || !this.authTokenMetaData) {
      return this.isTokenExpired();
    }
    return this.authTokenMetaData.expiresAt - Date.now() < this.tokenAlertTresholdMinutes * 60 * 1000;
  }

  watchTokenExpiration() {
    if (this.tokenExpirationHandler) {
      return;
    }
    this.tokenExpirationHandler = setInterval(() => this.tokenEvents$.next(this.tokenState), 5000);
  }

  @BitfTryCatch()
  activateRefreshToken() {
    if (!this.authTokenMetaData) {
      return;
    }
    const timer = Math.min(2147483647, Math.max(1, this.authTokenMetaData.expiresAt - Date.now()));
    if (this.refreshTokenHandler) {
      clearTimeout(this.refreshTokenHandler);
    }

    this.refreshTokenHandler = setTimeout(() => {
      this.refreshToken();
    }, timer - 10000);
  }

  @BitfTryCatch()
  refreshToken() {
    if (this.renewTokenRetryAttempt === this.renewTokenMaxAttempt) {
      // NOTE we are not doing sign out here, or show any ui messages because this is a silent refresh
      // We'll wait the next api call which will return 401 which will show a dialog
      return;
    }

    this.renewToken()
      .then(() => {
        this.renewTokenRetryAttempt = 0;
      })
      .catch(error => {
        // NOTE: the default implementation return a rejection for auth that doens't have the refresh
        // so we don't want to log that
        if (error) {
          this.bitfErrorHandlerService.handle(error);
        }
        this.renewTokenRetryAttempt++;
        setTimeout(() => {
          this.refreshToken();
        }, this.renewTokenDelay);
      });
  }

  async renewToken(): Promise<LoginType> {
    this.signIn(undefined);
    return Promise.reject();
  }

  onLoginSuccess() {
    this.authState$.next(EBitfAuthState.LOGGED);
  }

  onLoginError(errorMessage = '', authState: EBitfAuthState = EBitfAuthState.LOGIN_ERROR) {
    this.authState$.next(authState);
    this.clearAuthData();
    setTimeout(() => this.notifyClient(errorMessage, EBitfUiMessageType.ERROR));

    // NOTE this is to prevent the user refresh the page in login error state with an invalid token
    // this will reload the page without queryParams (the token) so the page will redirect again to the
    // oauth service login page
    if (location.search) {
      this.router.navigateByUrl(BITF_CONFIGS.urls.signInUrl);
    }
  }

  onLoginInfo(infoMessage = '', authState: EBitfAuthState = EBitfAuthState.LOGIN_INFO) {
    this.authState$.next(authState);
    setTimeout(() => this.notifyClient(infoMessage, EBitfUiMessageType.INFO));
  }

  handleRedirect(authState?: EBitfAuthState, ...args: any[]) {
    const storage = this.storageService.data;
    const redirectUrl = storage.redirectUrl;
    if (redirectUrl) {
      delete storage.redirectUrl;
      this.storageService.setData(storage);
      window.location.replace(redirectUrl);
    } else {
      this.router.navigate(['']);
    }
  }

  protected setUser(user: User) {
    this.storeService.setStore((store: Store) => {
      store.user = user;
    }, eStoreActions.SET_USER);
  }

  private notifyClient(message?: string, type = EBitfUiMessageType.ERROR) {
    let title = '';
    if (type === EBitfUiMessageType.ERROR) {
      title = this.translateService.instant(bitfToTranslate('BITF.LABEL.ERROR'));
    } else if (type === EBitfUiMessageType.INFO) {
      title = this.translateService.instant(bitfToTranslate('BITF.LABEL.INFO'));
    }
    this.storeService.store.uiMessages$.next({
      type: 'BitfUiMessages',
      strategy: EBitfUiMessageTarget.TOAST,
      payload: {
        title,
        type,
        message,
      } as IBitfToastData,
    } as IBitfUiMessages);
  }
}
