import { SuperModel } from './super-model.model';
import { TicketReport } from './ticket-report.model';

export class RideReport extends SuperModel {
  rideId: number;
  rideExternalId: number;
  rideShortName: string;
  ticketPurchased: {
    base: number;
    reduced: number;
    infant: number;
    luggage: number;
  };
  details: TicketReport[];
  constructor(data = {}) {
    super(data);
    this.details = this.details.map(detail => new TicketReport(detail));
  }

  get serialised() {
    return {
      rideId: this.rideId,
      rideExternalId: this.rideExternalId,
      rideShortName: this.rideShortName,
      ticketPurchased: this.ticketPurchased,
      details: this.details.map(detail => detail.serialised),
    };
  }
}
